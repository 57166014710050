import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { useCreateNotification } from '../../utils/toast.js';

function ConversationTypePipelineSelector({
  id,
  selection,
  onSelectionChange,
}) {
  const createNotification = useCreateNotification();
  const [pipelines, setPipelines] = useState([]);

  async function getPipelines() {
    try {
      const { data } = await axios.get(
        `/api/admin/conversation-type/${id}/pipelines`,
      );
      setPipelines(data);
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    }
  }

  useEffect(() => {
    getPipelines();
  }, [id]);

  const selectedPipeline = pipelines.find(
    (pipeline) => pipeline.id === selection.pipelineId,
  );

  return (
    <div>
      <div className="mb-3">
        <label htmlFor="pipeline-select" className="form-label">
          Select Pipeline:
        </label>
        <select
          id="pipeline-select"
          className="form-select"
          value={selection.pipelineId}
          onChange={(e) =>
            onSelectionChange({ pipelineId: e.target.value, stageId: '' })
          }
        >
          <option value="">Select a pipeline</option>
          {pipelines.map((pipeline) => (
            <option key={pipeline.id} value={pipeline.id}>
              {pipeline.name}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <label htmlFor="stage-select" className="form-label">
          Select Stage:
        </label>
        <select
          id="stage-select"
          className="form-select"
          value={selection.stageId}
          onChange={(e) =>
            onSelectionChange({ ...selection, stageId: e.target.value })
          }
          disabled={!selection.pipelineId}
        >
          <option value="">Select a stage</option>
          {selectedPipeline?.stages.map((stage) => (
            <option key={stage.id} value={stage.id}>
              {stage.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default ConversationTypePipelineSelector;
