import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export default function ProtectedRoute({ requiredRole }) {
  const userRole = useSelector((state) => state.auth.role);
  const location = useLocation();

  // Admin routes
  if (requiredRole === 'ADMIN' && userRole !== 'ADMIN') {
    // TODO: Make a forbidden page and a redirect after login using the state
    const path = userRole ? '/conversation/new' : '/login';
    return <Navigate to={path} state={location.pathname} />;
  }

  // Logged-in routes
  if (requiredRole === 'NORMAL' && !userRole) {
    return <Navigate to="/login" state={location.pathname} />;
  }

  return <Outlet />;
}
