import React from 'react';
import { Link } from 'react-router-dom';

function ConversationListTable({ conversations }) {
  return (
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>UUID</th>
            <th>Type</th>
            <th>Rating</th>
            <th>Phase</th>
            <th>Recording Duration</th>
            <th>Recording URL</th>
            <th>Messages</th>
          </tr>
        </thead>
        <tbody>
          {conversations.map((conversation) => (
            <tr key={conversation.id}>
              <td>
                <Link to={`/conversation/${conversation.uuid}`}>
                  {conversation.uuid}
                </Link>
              </td>
              <td>
                {conversation.conversationType ? (
                  <Link
                    to={
                      '/admin/conversation-type/' +
                      `${conversation.conversationType}/edit`
                    }
                  >
                    {conversation.ConversationType?.rolePrompt.substring(0, 30)}
                    ...
                  </Link>
                ) : (
                  'N/A'
                )}
              </td>
              <td>{conversation.rating}</td>
              <td>{conversation.phase}</td>
              <td>{conversation.recordingDuration}</td>
              <td>
                {conversation.recordingUrl ? (
                  <a
                    href={conversation.recordingUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Listen
                  </a>
                ) : (
                  'N/A'
                )}
              </td>
              <td>{conversation.messageCount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ConversationListTable;
