import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ConversationTypeSelector from '../../components/conversation/ConversationTypeSelector.jsx';
import { makeCall } from '../../utils/makeCall.js';
import { useCreateNotification } from '../../utils/toast.js';

export default function Call() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedTypeId, setSelectedTypeId] = useState('');
  const [contactId, setContactId] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const createNotification = useCreateNotification();
  const [useRealtimeApi, setUseRealtimeApi] = useState(true);

  async function handleSubmit(event) {
    event.preventDefault();
    if (submitting) return;
    setSubmitting(true);

    try {
      const data = await makeCall(
        phoneNumber,
        selectedTypeId,
        contactId,
        useRealtimeApi,
      );
      navigate(`/conversation/${data.uuid}?viewOnly=true`);
    } catch (error) {
      createNotification({
        message: error,
        type: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div
      className="container d-flex justify-content-center
        align-items-center min-vh-100"
    >
      <div className="card shadow-sm">
        <div className="card-body">
          <h5 className="card-title text-center mb-4">Make a Call</h5>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                type="tel"
                className="form-control mt-2"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Enter phone number"
                required
              />
              <input
                type="text"
                className="form-control mt-2"
                value={contactId}
                onChange={(e) => setContactId(e.target.value)}
                placeholder="Contact id (optional)"
              />
            </div>
            <div className="mb-3">
              <ConversationTypeSelector onSelect={setSelectedTypeId} required />
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="useRealtimeApi"
                checked={useRealtimeApi}
                onChange={(e) => setUseRealtimeApi(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="useRealtimeApi">
                Realtime API
              </label>
            </div>
            <button
              type="submit"
              disabled={!phoneNumber || !selectedTypeId}
              className="btn btn-primary w-100"
            >
              Call
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
