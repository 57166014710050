import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import CustomCard from '../utils/CustomCard.jsx';

export default function MessagingAccounts({ email }) {
  const [messagingAccounts, setMessagingAccounts] = useState([]);

  useEffect(() => {
    axios.get(`/api/user/${email}/messaging-accounts`).then((res) => {
      setMessagingAccounts(res.data.messagingAccounts);
    });
  }, [email]);

  async function handleDelete(accountId) {
    if (
      window.confirm(
        'Deleting this account will remove all conversations associated' +
          'with it.',
      )
    ) {
      try {
        await axios.delete(`/api/messaging-accounts/${accountId}`);
        setMessagingAccounts(
          messagingAccounts.filter((account) => account.id !== accountId),
        );
      } catch (error) {
        console.error('Error deleting account:', error);
      }
    }
  }

  return (
    <CustomCard width={6}>
      <h3 className="mb-4">Connected Accounts</h3>
      {messagingAccounts.map((account) => (
        <div
          key={account.id}
          className="d-flex justify-content-between align-items-center p-3
            border-bottom w-100"
        >
          <div className="d-flex align-items-center flex-grow-1">
            <i
              className={`lab ${
                account.messagingAccount.type === 'FACEBOOK'
                  ? 'la-facebook'
                  : 'la-instagram'
              } me-3 fs-4`}
            ></i>
            {account.messagingAccount.pageName}
          </div>
          {account.messagingAccount.conversationType && (
            <Link
              to={`/conversation/list?conversationType=${
                account.messagingAccount.conversationType.id
              }`}
              className="btn btn-sm btn-outline-primary me-2"
            >
              View Conversations
            </Link>
          )}
          <button
            onClick={() => handleDelete(account.id)}
            className="btn btn-sm btn-outline-danger"
          >
            Delete account
            <i className="las la-trash-alt ms-2"></i>
          </button>
        </div>
      ))}
    </CustomCard>
  );
}
