import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { useCreateNotification } from '../../utils/toast.js';

export default function MessagingAccountsDropdown({
  selectedAccountId = null,
  onChange,
}) {
  const [messagingAccounts, setMessagingAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const createNotification = useCreateNotification();

  useEffect(() => {
    async function fetchMessagingAccounts() {
      try {
        const { data } = await axios.get(
          '/api/admin/meta-api/messaging-accounts',
        );
        setMessagingAccounts(data);
      } catch (error) {
        createNotification({ message: error, type: 'error' });
      } finally {
        setIsLoading(false);
      }
    }

    fetchMessagingAccounts();
  }, []);

  function formatAccountName(account) {
    let assignmentStatus = '(unassigned)';
    if (account.conversationTypeId) {
      assignmentStatus = `(assigned to type: ${account.conversationTypeId})`;
    }
    const expiryDate = new Date(account.expirationDate).toLocaleDateString();

    return (
      `[${account.type}] ${account.pageName} ${assignmentStatus} ` +
      `(expires: ${expiryDate})`
    );
  }

  return (
    <select
      className="form-select"
      value={selectedAccountId || ''}
      onChange={(e) => onChange(e.target.value ? Number(e.target.value) : null)}
      disabled={isLoading}
    >
      <option value="">Select a messaging account</option>
      {messagingAccounts.map((account) => (
        <option key={account.id} value={account.id}>
          {formatAccountName(account)}
        </option>
      ))}
    </select>
  );
}
