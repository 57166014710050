import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Dropzone from '../components/upload/Dropzone/Dropzone.jsx';
import CustomCard from '../components/utils/CustomCard.jsx';
import CustomInput from '../components/utils/CustomInput.jsx';
import CustomModal from '../components/utils/CustomModal.jsx';
import ResponseMessage from '../components/utils/ResponseMessage.jsx';
import { getErrorMessage } from '../utils/toast.js';
import { validateIsEmail } from '../utils/validators.js';

function UploadFile() {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
    title: '',
  });

  function handleDrop(acceptedFiles) {
    setFile(acceptedFiles[0]);
    setError('');
    setUploadProgress(0);
  }

  function handleError(errorMessage) {
    setError(errorMessage);
    setFile(null);
    setUploadProgress(0);
    openModal('error', 'Error');
  }

  function handleEmailChange(e) {
    setEmail(e.target.value);
    setError('');
  }

  function openModal(type, title) {
    setModalState({ isOpen: true, type, title });
  }

  function closeModal() {
    setError('');
    setSuccess('');
    setModalState({ isOpen: false, type: null, title: '' });
  }

  async function handleUpload() {
    if (!file) {
      handleError('Please select an audio file.');
      return;
    }

    if (!validateIsEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    setError('');
    setSuccess('');
    setIsUploading(true);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('email', email);

    try {
      const response = await axios.post('/api/upload-file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          setUploadProgress(percentCompleted);
        },
      });

      if (response.status === 200) {
        setSuccess('File uploaded successfully!');
        setFile(null);
        setEmail('');
        openModal('success', 'Status');
        navigate(`/evaluation/${response.data.uuid}`);
      } else {
        setError(response.data.error);
      }
    } catch (error) {
      setError(getErrorMessage(error));
    } finally {
      setIsUploading(false);
    }
  }

  function renderModalContent() {
    if (modalState.type === 'email') {
      return (
        <>
          <ResponseMessage message={error} type="error" />
          <ResponseMessage message={success} type="success" />
          <CustomInput
            label="Email"
            id="email-modal"
            type="email"
            value={email}
            onChange={handleEmailChange}
          />
          <div className="d-flex justify-content-end mt-4">
            <button
              className="btn btn-primary"
              onClick={handleUpload}
              disabled={isUploading}
            >
              {isUploading ? 'Uploading...' : 'Confirm upload'}
            </button>
          </div>
          {isUploading && (
            <div className="progress mt-2">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${uploadProgress}%` }}
                aria-valuenow={uploadProgress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {uploadProgress}%
              </div>
            </div>
          )}
        </>
      );
    } else if (modalState.type === 'error') {
      return <ResponseMessage message={error} type="error" />;
    } else if (modalState.type === 'success') {
      return <ResponseMessage message={success} type="success" />;
    }
    return null;
  }

  return (
    <CustomCard>
      <h4 className="card-title">Audio File Upload</h4>
      <Dropzone onDrop={handleDrop} onError={handleError} />
      {file && (
        <div className="mt-3">
          <h5>Selected File:</h5>
          <p>{file.name}</p>
          <p>{(file.size / (1024 * 1024)).toFixed(2)} MB</p>
          <button
            className="btn btn-primary"
            onClick={() => openModal('email', 'Confirm email')}
            disabled={isUploading}
          >
            Upload
          </button>
        </div>
      )}
      <CustomModal
        isOpen={modalState.isOpen}
        onClose={closeModal}
        title={modalState.title}
        content={renderModalContent()}
      />
    </CustomCard>
  );
}

export default UploadFile;
