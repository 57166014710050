import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { login } from '../../features/auth.js';
import { useCreateNotification } from '../../utils/toast.js';

export default function MetaApiLoginRegisterRedirect() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createNotification = useCreateNotification();
  const [status, setStatus] = useState('Processing...');

  useEffect(() => {
    const hash = location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const access_token = params.get('access_token');
    if (access_token) {
      handleMetaLoginRegisterRedirect(access_token);
    } else {
      setStatus('Error: No authorization access_token received');
    }
  }, [location]);

  async function handleMetaLoginRegisterRedirect(code) {
    try {
      const { data } = await axios.post(
        '/api/meta-api/meta-login-register-redirect',
        {
          code,
        },
      );
      setStatus('Login successful!');
      dispatch(login({ email: data.session.email, role: data.session.role }));
      setTimeout(() => navigate(`/user/${data.session.email}`), 3000);
    } catch (error) {
      createNotification({ message: error, type: 'error' });
      setStatus('Error connecting Instagram account');
    }
  }

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">Authentication</h2>
          <p className="card-text">{status}</p>
        </div>
      </div>
    </div>
  );
}
