import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import FacebookLoginButton from '../../components/user/FacebookLoginButton.jsx';
import CustomCard from '../../components/utils/CustomCard.jsx';
import CustomInput from '../../components/utils/CustomInput.jsx';
import { login } from '../../features/auth.js';
import { useCreateNotification } from '../../utils/toast.js';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const createNotification = useCreateNotification();

  function validateForm() {
    if (!email) {
      createNotification({ message: 'Email is required', type: 'error' });
      return false;
    }
    if (!password) {
      createNotification({
        message: 'Password is required',
        type: 'error',
      });
      return false;
    }
    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (isSubmitting) return;

    if (!validateForm()) return;

    setIsSubmitting(true);

    try {
      const { data } = await axios.post('/api/user/login', {
        email,
        password,
      });
      dispatch(login({ email: data.session.email, role: data.session.role }));
      createNotification({
        message: 'Login successful',
        type: 'success',
      });
      navigate('/', { replace: true });
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <CustomCard width={3}>
      <form onSubmit={handleSubmit}>
        <CustomInput
          label="Email"
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <CustomInput
          label="Password"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          type="submit"
          disabled={isSubmitting}
          className="btn btn-primary mt-3 mb-4 w-100"
        >
          Log In
        </button>
      </form>
      <span>
        Don&apos;t have an account?{' '}
        <Link
          to="/register"
          className="text-decoration-none text-primary fw-semibold"
        >
          Register
        </Link>
      </span>
      <FacebookLoginButton />
    </CustomCard>
  );
}
