import React from 'react';

function Pagination({
  currentPage,
  totalPages,
  onPageChange,
  isLoading,
  previousLabel = 'Previous',
  nextLabel = 'Next',
}) {
  return (
    <nav>
      <ul className="pagination justify-content-center">
        <li className={`page-item ${currentPage <= 1 ? 'disabled' : ''}`}>
          <button
            className="page-link"
            onClick={() => onPageChange(currentPage - 1)}
            disabled={isLoading || currentPage === 1}
          >
            {previousLabel}
          </button>
        </li>
        <li className="page-item">
          <span className="page-link">
            {currentPage} of {totalPages}
          </span>
        </li>
        <li
          className={`page-item ${
            currentPage === totalPages ? 'disabled' : ''
          }`}
        >
          <button
            className="page-link"
            onClick={() => onPageChange(currentPage + 1)}
            disabled={isLoading || currentPage === totalPages}
          >
            {nextLabel}
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
