import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeToast } from '../features/notificationContent.js';

const TOAST_DURATION_MS = 3000;

function NotificationToast() {
  const { messages } = useSelector((state) => state.notificationContent);
  const dispatch = useDispatch();

  useEffect(() => {
    if (messages.length > 0) {
      const timer = setTimeout(() => {
        dispatch(removeToast(messages[0].id));
      }, TOAST_DURATION_MS);

      return () => clearTimeout(timer);
    }
  }, [messages]);

  return (
    <div
      className="position-fixed top-0 start-50 translate-middle-x mt-3"
      style={{ zIndex: 1050, maxWidth: '21.875rem' }}
    >
      <div className="d-flex flex-column align-items-center w-100">
        {messages.map((msg) => (
          <div
            key={msg.id}
            className="toast show w-100 mb-2"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className={`toast-header bg-${msg.type} text-white`}>
              <strong className="me-auto">
                {/* TODO: Use descriptive titles for 'primary',
                  'secondary', 'light', etc. */}
                {msg.type.charAt(0).toUpperCase() + msg.type.slice(1)}
              </strong>
              <button
                type="button"
                className="btn-close btn-close-white"
                onClick={() => dispatch(removeToast(msg.id))}
                aria-label="Close"
              ></button>
            </div>
            <div
              className={`toast-body bg-${msg.type}-subtle text-${msg.type}`}
            >
              {msg.message}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NotificationToast;
