import React from 'react';

export default function PricingResults({ costs, title = 'Pricing Results' }) {
  if (!costs) return null;

  return (
    <div className="card mt-3">
      <div className="card-header bg-secondary text-white">{title}</div>
      <div className="card-body">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Cost Item</th>
              <th className="text-end">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total GPT Cost</td>
              <td className="text-end">${costs.totalGptCost.toFixed(6)}</td>
            </tr>
            <tr>
              <td>Total Azure TTS Cost</td>
              <td className="text-end">${costs.totalAzureCost.toFixed(6)}</td>
            </tr>
            <tr>
              <td>Twilio Voice Minutes</td>
              <td className="text-end">${costs.voiceMinutes.toFixed(6)}</td>
            </tr>
            <tr>
              <td>Twilio Speech Recognition</td>
              <td className="text-end">
                ${costs.speechRecognition.toFixed(6)}
              </td>
            </tr>
            <tr>
              <td>Twilio Recordings (Monthly)</td>
              <td className="text-end">${costs.twilioRecordings.toFixed(6)}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr className="fw-bold">
              <td>Total Cost</td>
              <td className="text-end">${costs.totalCosts.toFixed(6)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
