import React from 'react';

import { makeCall } from '../../utils/makeCall.js';
import { useCreateNotification } from '../../utils/toast.js';

export default function ContactRow({ opportunity, conversationTypeId }) {
  const createNotification = useCreateNotification();
  const { contact } = opportunity;

  async function handleMakeCall(event, phone, typeId, contactId) {
    const button = event.currentTarget;
    // Disable the clicked button to prevent multiple calls
    button.disabled = true;

    try {
      const data = await makeCall(phone, typeId, contactId);
      // Open the conversation in a new tab
      const url = `/conversation/${data.uuid}?viewOnly=true`;
      window.open(url, '_blank');
    } catch (error) {
      createNotification({
        message: error,
        type: 'error',
      });
      button.disabled = false;
    }
  }

  const actions = [
    {
      label: 'Call',
      onClick: (e) =>
        handleMakeCall(e, contact.phone, conversationTypeId, contact.id),
      type: 'primary',
    },
  ];

  return (
    <tr>
      <td>{contact.name}</td>
      <td>{contact.phone}</td>
      <td>
        {actions.map((action, index) => (
          <button
            key={index}
            className={`btn btn-${action.type || 'primary'} btn-sm mx-1`}
            onClick={action.onClick}
          >
            {action.label}
          </button>
        ))}
      </td>
    </tr>
  );
}
