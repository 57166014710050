import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import MessagingAccountConnector from '../../components/messaging/MessagingAccountConnector.jsx';
import MessagingAccounts from '../../components/messaging/MessagingAccounts.jsx';
import CustomCard from '../../components/utils/CustomCard.jsx';
import ErrorCard from '../../components/utils/ErrorCard.jsx';
import Loader from '../../components/utils/Loader.jsx';
import { useCreateNotification } from '../../utils/toast.js';

export default function Profile() {
  const { email } = useParams();
  const [userData, setUserData] = useState({ email: '', role: '' });
  const [loading, setLoading] = useState(true);

  const createNotification = useCreateNotification();

  async function getProfileData() {
    try {
      const encodedEmail = encodeURIComponent(email);
      const { data } = await axios.get(`/api/user/${encodedEmail}`);
      setUserData(data);
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getProfileData();
  }, [email]);

  async function deleteUser() {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await axios.delete(`/api/user/delete`, {
          data: { email: userData.email },
        });
        location.reload();
      } catch (error) {
        createNotification({ message: error, type: 'error' });
      }
    }
  }

  if (loading) {
    return <Loader />;
  }

  if (!userData.email) {
    return <ErrorCard error="User not found" />;
  }

  return (
    <>
      <MessagingAccounts email={userData.email} />
      <MessagingAccountConnector email={userData.email} />
      <CustomCard width={6}>
        <h3 className="mb-4">Your Profile</h3>
        <div className="w-100">
          <div className="mb-3">
            <label className="fw-bold">Email:</label>
            <div className="form-control bg-light">{userData.email}</div>
          </div>
          <div className="mb-3">
            <label className="fw-bold">Role:</label>
            <div className="form-control bg-light text-capitalize">
              {userData.role}
            </div>
          </div>
          <button className="btn btn-danger" onClick={deleteUser}>
            Delete User
          </button>
        </div>
      </CustomCard>
    </>
  );
}
