import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {'error' | 'success' | 'info' | 'warning' | 'primary' |
 *   'secondary' | 'light' | 'dark'} ToastType
 */

/**
 * @typedef {Object} Toast
 * @property {number} id
 * @property {string} message
 * @property {ToastType} type
 */

/**
 * @typedef {Object} NotificationState
 * @property {Toast[]} messages
 */

/** @type {NotificationState} */
const initialState = {
  messages: [],
};

const notificationSlice = createSlice({
  name: 'notificationContent',
  initialState,
  reducers: {
    /**
     * @param {NotificationState} state
     * @param {{payload: {message: string, type: ToastType}}} action
     */
    addToast: (state, action) => {
      // Type corresponds to Bootstrap 5 alert types
      // See: https://getbootstrap.com/docs/5.3/components/alerts/
      const { message, type } = action.payload;

      state.messages.push({
        id: Date.now(),
        message,
        type,
      });
    },
    /**
     * @param {NotificationState} state
     * @param {{payload: number}} action
     */
    removeToast: (state, action) => {
      state.messages = state.messages.filter(
        (msg) => msg.id !== action.payload,
      );
    },
  },
});

export const { addToast, removeToast } = notificationSlice.actions;

export default notificationSlice.reducer;
