import React, { useState } from 'react';

import { truncateText } from '../../utils/string.js';
import ConversationTypeSelector from '../conversation/ConversationTypeSelector.jsx';

const MAX_TEXT_LENGTH = 70;

export default function PromptSelector({
  onSystemPromptChange,
  onUserPromptChange,
}) {
  const [selectedConversation, setSelectedConversation] = useState(null);

  return (
    <>
      <div className="row mt-3">
        <div className="col">
          <ConversationTypeSelector
            setSelectedConversation={setSelectedConversation}
          />
        </div>
      </div>
      {selectedConversation && (
        <>
          <div className="row mt-3">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Selected Conversation Prompt</h5>
                  <p className="card-text">{selectedConversation.rolePrompt}</p>
                </div>
                <div
                  className="card-footer d-flex justify-content-between
                    align-items-center"
                >
                  <div>Length: {selectedConversation.rolePrompt.length}</div>
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() =>
                      onSystemPromptChange(
                        selectedConversation.rolePrompt.length,
                      ) || 0
                    }
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Index</th>
                    <th>Prompt</th>
                    <th>Length</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedConversation.conversation_phases.map((phase) => (
                    <tr key={phase.id}>
                      <td>{phase.index}</td>
                      <td>{truncateText(phase.prompt, MAX_TEXT_LENGTH)}</td>
                      <td>{phase.prompt.length}</td>
                      <td>
                        <button
                          className="btn btn-outline-primary btn-sm"
                          onClick={() =>
                            onUserPromptChange(phase.prompt.length) || 0
                          }
                        >
                          Apply
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
}
