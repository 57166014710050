import React from 'react';

import ContactRow from './ContactRow.jsx';

export default function ContactsTable({ contacts, conversationTypeId }) {
  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover table-bordered">
        <colgroup>
          <col style={{ width: '50%' }} />
          <col style={{ width: '47%' }} />
          <col style={{ width: '3%' }} />
        </colgroup>
        <thead className="table-light">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Phone</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {contacts.opportunities.map((opportunity) => (
            <ContactRow
              key={opportunity.contact.id}
              opportunity={opportunity}
              conversationTypeId={conversationTypeId}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
