import axios from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { logout } from '../../features/auth.js';
import { useCreateNotification } from '../../utils/toast.js';

export default function MenuDropdown() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createNotification = useCreateNotification();
  const email = useSelector((state) => state.auth.email);

  async function handleLogout() {
    try {
      await axios.post('/api/user/logout');
      dispatch(logout());
      navigate('/login');
    } catch (error) {
      createNotification({
        message: error,
        type: 'error',
      });
    }
  }

  return (
    <div className="dropdown">
      <button
        type="button"
        className="nav-link dropdown-toggle bg-transparent border-0 p-0"
        id="user-dropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span>{email}</span>
      </button>
      <ul
        className="dropdown-menu dropdown-menu-end"
        aria-labelledby="user-dropdown"
      >
        <li>
          <Link className="dropdown-item" to={`/user/${email}`}>
            Profile
          </Link>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <button
            type="button"
            className="dropdown-item"
            onClick={handleLogout}
          >
            Logout
          </button>
        </li>
      </ul>
    </div>
  );
}
