import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCreateNotification } from '../../utils/toast.js';

export default function MetaApiRedirect() {
  const location = useLocation();
  const navigate = useNavigate();

  const createNotification = useCreateNotification();
  const [status, setStatus] = useState('Processing...');

  useEffect(() => {
    const hash = location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const access_token = params.get('access_token');
    if (access_token) {
      handleMetaApiAuth(access_token);
    } else {
      const newParams = new URLSearchParams(location.search);
      const code = newParams.get('code');
      if (code) {
        handleIgApiAuth(code);
      } else {
        setStatus('Error: No authorization access_token received');
      }
    }
  }, [location]);

  async function handleIgApiAuth(code) {
    try {
      const { data } = await axios.post('/api/meta-api/ig-api-redirect', {
        code,
      });
      setStatus(`Connected to ${data.pageName} successfully!`);
      setTimeout(() => navigate(`/user/${data.session.email}`), 3000);
    } catch (error) {
      createNotification({ message: error, type: 'error' });
      setStatus('Error connecting Instagram account');
    }
  }

  async function handleMetaApiAuth(code) {
    try {
      const { data } = await axios.post('/api/meta-api/meta-api-redirect', {
        code,
      });
      setStatus('Accounts connected successfully!');
      setTimeout(() => navigate(`/user/${data.session.email}`), 3000);
    } catch (error) {
      createNotification({ message: error, type: 'error' });
      setStatus('Error connecting Instagram account');
    }
  }

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">Authentication</h2>
          <p className="card-text">{status}</p>
        </div>
      </div>
    </div>
  );
}
