import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import FacebookLoginButton from '../../components/user/FacebookLoginButton.jsx';
import CustomCard from '../../components/utils/CustomCard.jsx';
import CustomInput from '../../components/utils/CustomInput.jsx';
import { useCreateNotification } from '../../utils/toast.js';

export default function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const createNotification = useCreateNotification();

  function validateForm() {
    if (!email) {
      createNotification({ message: 'Email is required', type: 'error' });
      return false;
    }
    if (!password || password.length < 8) {
      createNotification({
        message: 'Password must be at least 8 characters long',
        type: 'error',
      });
      return false;
    }
    if (!confirmPassword || password !== confirmPassword) {
      createNotification({
        message: 'Passwords do not match',
        type: 'error',
      });
      return false;
    }
    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (isSubmitting) return;

    if (!validateForm()) return;

    setIsSubmitting(true);

    try {
      await axios.post('/api/user/register', {
        email,
        password,
      });
      createNotification({
        message: 'Registration successful',
        type: 'success',
      });
      navigate('/login', { replace: true });
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <CustomCard width={3}>
      <form onSubmit={handleSubmit}>
        <CustomInput
          label="Email"
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <CustomInput
          label="Password"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <CustomInput
          label="Confirm Password"
          id="confirmPassword"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button
          type="submit"
          disabled={isSubmitting}
          className="btn btn-primary mt-3 mb-4 w-100"
        >
          Register
        </button>
      </form>
      <span>
        Already have an account?{' '}
        <Link
          to="/login"
          className="text-decoration-none text-primary fw-semibold"
        >
          Log in
        </Link>
      </span>
      <FacebookLoginButton />
    </CustomCard>
  );
}
