import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import RecordRTC from 'recordrtc';

import { adjustTextAreaHeight } from '../../utils/textInput.js';
import { getErrorMessage, useCreateNotification } from '../../utils/toast.js';

export default function ViewConversation() {
  const { uuid } = useParams();
  let location = useLocation();

  const [isRecording, setIsRecording] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [conversation, setConversation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [idx, setIdx] = useState(0);
  const [newContent, setNewContent] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);

  const mediaRecorderRef = useRef(null);
  const messagesEndRef = useRef(null);
  const messageInputRef = useRef(null);

  const createNotification = useCreateNotification();

  async function onStopRecording(audioBlob) {
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('recording', audioBlob);
      const response = await axios.post(
        `/api/conversation/${uuid}/voice`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );
      console.log(response);

      fetchConversation();
      setIdx(idx + 1);
      const reply = new Audio(
        `/api/voice-reply/${response.data.agentMessageId}`,
      );
      if (!isPlaying) {
        setIsPlaying(true);
        await reply.play();
        setIsPlaying(false);
      }

      console.log('Recording uploaded successfully');
    } catch (error) {
      createNotification({
        message: `Failed to upload recording: ${error}`,
        type: 'error',
      });
    } finally {
      setIsUploading(false);
    }
  }

  function toggleRecording() {
    if (isViewOnly) return;

    if (isRecording) {
      mediaRecorderRef.current.stopRecording(() => {
        const blob = mediaRecorderRef.current.getBlob();
        onStopRecording(blob);
      });
    } else {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          mediaRecorderRef.current = new RecordRTC(stream, {
            type: 'audio',
            mimeType: 'audio/wav',
            recorderType: RecordRTC.StereoAudioRecorder,
            desiredSampRate: 16000,
          });
          mediaRecorderRef.current.startRecording();
        })
        .catch((error) => {
          createNotification({
            message: `Error accessing microphone: ${error}`,
            type: 'error',
          });
        });
    }
    setIsRecording(!isRecording);
  }

  async function fetchConversation() {
    try {
      const { data } = await axios.get(`/api/conversation/${uuid}`);
      if (
        conversation &&
        conversation.messages.length !== data.messages.length
      ) {
        setNewContent(true);
      }
      setConversation(data);
      setError(null);
    } catch (err) {
      setError(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const replyId = location.state?.replyId;
    if (replyId) {
      const reply = new Audio(`/api/voice-reply/${replyId}`);
      if (!isPlaying) {
        setIsPlaying(true);
        reply.play();
        location.state.replyId = null;
        setIsPlaying(false);
      }
    }
  }, []);

  useEffect(() => {
    fetchConversation();
    const intervalId = setInterval(fetchConversation, 10000);
    const params = new URLSearchParams(location.search);
    setIsViewOnly(params.get('viewOnly') === 'true');

    return () => clearInterval(intervalId);
  }, [uuid]);

  useEffect(() => {
    if (newContent) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      setNewContent(false);
      if (messageInputRef.current) {
        messageInputRef.current.focus();
      }
    }
  }, [newContent]);

  async function sendWrittenMessage(e) {
    e.preventDefault();
    if (!message.trim() || isSending) return;

    setIsSending(true);
    try {
      await axios.post(`/api/conversation/${uuid}/written-message`, {
        message: message.trim(),
      });
      setMessage('');
      fetchConversation();
    } catch (error) {
      createNotification({
        message: error,
        type: 'error',
      });
    } finally {
      setIsSending(false);
    }
  }

  if (loading) return <div className="text-center py-4">Loading...</div>;
  if (error)
    return <div className="text-center py-4 text-danger">Error: {error}</div>;
  if (!conversation)
    return <div className="text-center py-4">No conversation found</div>;

  return (
    <div className="container py-5" id={idx}>
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="card h-100 d-flex flex-column">
            <div className="card-body flex-grow-1 overflow-auto p-4">
              {conversation.messages.map((message, index) => (
                <div
                  key={index}
                  className={`mb-4 ${
                    message.role === 'AGENT' ? 'text-start' : 'text-end'
                  }`}
                >
                  <div
                    className={`d-inline-block p-3 rounded ${
                      message.role === 'AGENT'
                        ? 'bg-light'
                        : 'bg-primary text-white'
                    }`}
                  >
                    <p className="mb-1">{message.message}</p>
                    <small
                      className={
                        message.role === 'AGENT' ? 'text-muted' : 'text-white'
                      }
                    >
                      {new Date(message.dateSent).toLocaleString()}
                    </small>
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
            <div className="card-footer p-3 border-top">
              {isUploading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  {!isViewOnly && (
                    <>
                      <form
                        onSubmit={sendWrittenMessage}
                        className="d-flex gap-2"
                      >
                        <textarea
                          ref={messageInputRef}
                          className="form-control"
                          value={message}
                          onChange={(e) => {
                            setMessage(e.target.value);
                            adjustTextAreaHeight(e.target);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                              e.preventDefault();
                              sendWrittenMessage(e);
                            }
                          }}
                          placeholder="Type your message..."
                          disabled={isSending}
                          rows="1"
                        />
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSending || !message.trim()}
                        >
                          {isSending ? (
                            <span className="spinner-border spinner-border-sm" />
                          ) : (
                            <i className="bi bi-send"></i>
                          )}
                        </button>
                      </form>
                      <button
                        onClick={toggleRecording}
                        className={`mt-3 btn btn-lg w-100 ${
                          isRecording ? 'btn-danger' : 'btn-primary'
                        }`}
                      >
                        <i className="bi bi-mic-fill me-2"></i>
                        {isRecording ? 'Stop Talking' : 'Start Talking'}
                      </button>
                    </>
                  )}
                  <Link to={`/conversation/${uuid}/performance`}>
                    <button className="btn btn-lg w-100 btn-secondary mt-3">
                      <i className="bi bi-graph-up-arrow me-2"></i>
                      Analyze Call Performance
                    </button>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
