import axios from 'axios';
import { useState } from 'react';

import MessagingAccountsDropdown from '../../components/messaging/MessagingAccountsDropdown.jsx';
import { useCreateNotification } from '../../utils/toast.js';

export default function WebhookSimulationTool() {
  const [senderId, setSenderId] = useState('');
  const [receiverId, setReceiverId] = useState('');
  const [message, setMessage] = useState('');
  const createNotification = useCreateNotification();

  async function handleSubmit(e) {
    e.preventDefault();

    const webhookPayload = {
      object: 'instagram',
      entry: [
        {
          id: receiverId,
          time: Date.now(),
          messaging: [
            {
              sender: {
                id: senderId,
              },
              recipient: {
                id: receiverId,
              },
              timestamp: Date.now(),
              message: {
                mid: `simulate_mid_${Date.now()}`,
                text: message,
              },
            },
          ],
        },
      ],
    };

    try {
      const receiverAccount = await axios.get(
        `/api/admin/messaging-accounts/${parseInt(receiverId)}`,
      );
      const senderAccount = await axios.get(
        `/api/admin/messaging-accounts/${senderId}`,
      );

      webhookPayload.entry[0].messaging[0].recipient.id =
        receiverAccount.data.pageId;
      webhookPayload.entry[0].messaging[0].sender.id =
        senderAccount.data.pageId;

      await axios.post('/api/meta-api/webhook', webhookPayload);

      createNotification({
        message: 'Webhook simulated successfully!',
        type: 'success',
      });

      setMessage('');
    } catch (error) {
      createNotification({
        message: `Error simulating webhook: ${error}`,
        type: 'error',
      });
    }
  }

  return (
    <div className="container py-4">
      <div className="card shadow-sm">
        <div className="card-header bg-primary text-white">
          <h1 className="h4 mb-0">Instagram Webhook Simulator</h1>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row mb-3">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Sender Account:</label>
                  <MessagingAccountsDropdown
                    selectedAccountId={senderId}
                    onChange={(value) => setSenderId(value)}
                    className="form-select"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Receiver Account:</label>
                  <MessagingAccountsDropdown
                    selectedAccountId={receiverId}
                    onChange={(value) => setReceiverId(value)}
                    className="form-select"
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3">
              <label className="form-label">Message Text:</label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="form-control"
                rows="4"
                required
                placeholder="Enter message content..."
              />
            </div>

            <div className="d-grid">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!senderId || !receiverId || !message}
              >
                <i className="bi bi-send me-2"></i>
                Simulate Webhook
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
