import { configureStore } from '@reduxjs/toolkit';

import authReducer from '../features/auth';
import notificationContentReducer from '../features/notificationContent.js';

const store = configureStore({
  reducer: {
    auth: authReducer,
    notificationContent: notificationContentReducer,
  },
});

export default store;
