import React from 'react';
import { Link } from 'react-router-dom';

export default function FacebookLoginButton() {
  const fbLoginUrl =
    'https://www.facebook.com/dialog/oauth' +
    '?response_type=token' +
    '&display=page' +
    `&client_id=${process.env.REACT_APP_META_CLIENT_ID}` +
    '&redirect_uri=' +
    encodeURIComponent(
      `${process.env.REACT_APP_BASE_URL}/user/meta-login-register-redirect`,
    ) +
    `&scope=${encodeURIComponent('email,public_profile,pages_show_list')}`;

  return (
    <>
      <div className="d-grid gap-2 mt-4">
        <Link
          to={fbLoginUrl}
          className="facebook-login-button w-100 text-decoration-none"
        >
          <i className="bi bi-facebook facebook-icon"></i>
          Continue with Facebook
        </Link>
      </div>
      <div className="mt-4 text-center small">
        <p className="text-muted mb-2">
          By clicking &quot;Continue with Facebook&quot;, you agree to our Terms
          & Conditions and Privacy Policy
        </p>
        <p className="text-muted">
          First time users will automatically have an account created.
        </p>
      </div>
    </>
  );
}
