import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="__footer bg-light">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <span className="text-muted">
            © 2024 SalesWell. All rights reserved.
          </span>
          <div>
            <Link
              to="https://saleswell.us/terms-conditions/"
              className="text-muted me-3"
            >
              Terms and Conditions
            </Link>
            <Link
              to="https://saleswell.us/privacy-policy/"
              className="text-muted"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
