import { Link } from 'react-router-dom';

// Deprecated
export default function MetaInvite() {
  const fbLoginUrl =
    'https://www.facebook.com/dialog/oauth' +
    '?response_type=token' +
    '&display=page' +
    '&extras={"setup":{"channel":"IG_API_ONBOARDING"}}' +
    // TODO: Move to .env
    `&client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}` +
    '&redirect_uri=' +
    encodeURIComponent(
      `${process.env.REACT_APP_FACEBOOK_REDIRECT_URI}/user/ig-redirect`,
    ) +
    '&scope=email,pages_show_list,business_management,pages_messaging,' +
    'pages_messaging_subscriptions,instagram_basic,instagram_manage_comments,' +
    'instagram_content_publish,instagram_manage_messages,page_events,' +
    'pages_read_engagement,pages_read_user_content,pages_manage_posts,' +
    'pages_manage_engagement,instagram_manage_events';

  return (
    <div className="meta-invite-container">
      <div className="permissions-info">
        <h2>Before you connect</h2>
        <p>By continuing with Facebook, you understand that:</p>
        <ul>
          <li>We will receive messages sent to your connected Facebook Page</li>
          <li>
            Once connected, the AI agent will automatically respond to anyone
            who messages your page
          </li>
          <li>
            You can pause or disable the AI agent at any time by messaging us
          </li>
        </ul>
      </div>

      <Link to={fbLoginUrl} className="facebook-login-button">
        <span className="facebook-icon">
          <i className="lab la-facebook-f"></i>
        </span>
        <span className="facebook-text">Continue with Facebook</span>
      </Link>
    </div>
  );
}
