import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Pagination from '../../components/Pagination.jsx';
import ConversationListFilter from '../../components/conversation/ConversationListFilter.jsx';
import ConversationListTable from '../../components/conversation/ConversationListTable.jsx';
import Loader from '../../components/utils/Loader.jsx';
import { useCreateNotification } from '../../utils/toast.js';

function ConversationList() {
  const navigate = useNavigate();
  const location = useLocation();
  const createNotification = useCreateNotification();

  const [refreshIndex, setRefreshIndex] = useState(0);
  const [conversations, setConversations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState({
    uuid: '',
    conversationType: '',
    minRating: '',
    maxRating: '',
    minRecordingDuration: '',
    maxRecordingDuration: '',
    hasRecording: '',
  });
  const [isLoading, setIsLoading] = useState(true);

  async function fetchConversations() {
    try {
      const { data } = await axios.get('/api/conversation/list', {
        params: {
          page: currentPage,
          limit: limit,
          ...filters,
        },
      });
      setConversations(data.conversations);
      setTotalPages(data.totalPages);
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    setCurrentPage(Number(searchParams.get('page')) || 1);
    setLimit(Number(searchParams.get('limit')) || 10);

    const filterKeys = {
      uuid: '',
      conversationType: '',
      minRating: '',
      maxRating: '',
      minRecordingDuration: '',
      maxRecordingDuration: '',
      hasRecording: '',
    };

    const newFilters = Object.fromEntries(
      Object.entries(filterKeys).map(([key, defaultValue]) => {
        if (
          key.includes('conversationType') ||
          key.includes('Rating') ||
          key.includes('Duration')
        ) {
          return [key, Number(searchParams.get(key)) || defaultValue];
        }
        return [key, searchParams.get(key) || defaultValue];
      }),
    );

    setFilters(newFilters);

    setRefreshIndex((prevIndex) => prevIndex + 1);
  }, [location.search]);

  useEffect(() => {
    if (refreshIndex > 0) {
      fetchConversations();
    }
  }, [refreshIndex]);

  function updateURL(params) {
    const searchParams = new URLSearchParams(location.search);
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    });
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  }

  function handleFilterChange(e) {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    setCurrentPage(1);
    updateURL({ [name]: value, page: 1 });
    setRefreshIndex((prevIndex) => prevIndex + 1);
  }

  function handlePageChange(newPage) {
    setCurrentPage(newPage);
    updateURL({ page: newPage });
    setRefreshIndex((prevIndex) => prevIndex + 1);
  }

  function handleLimitChange(e) {
    const newLimit = Number(e.target.value);
    setLimit(newLimit);
    setCurrentPage(1);
    updateURL({ limit: newLimit, page: 1 });
    setRefreshIndex((prevIndex) => prevIndex + 1);
  }

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Conversations</h1>
      <ConversationListFilter
        filters={filters}
        handleFilterChange={handleFilterChange}
        handleConversationTypeSelect={(typeId) =>
          handleFilterChange({
            target: { name: 'conversationType', value: typeId },
          })
        }
        handleLimitChange={handleLimitChange}
        limit={limit}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <ConversationListTable
          conversations={conversations}
          refreshIndex={refreshIndex}
        />
      )}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        isLoading={isLoading}
      />
    </div>
  );
}

export default ConversationList;
