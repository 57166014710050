import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import axios from 'axios';
import { useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import { useParams } from 'react-router-dom';

import Loader from '../../../components/utils/Loader.jsx';
import { promptTruncatedLength, truncateText } from '../../../utils/string';
import { useCreateNotification } from '../../../utils/toast.js';

export default function ConfigConversationType() {
  const { id } = useParams();
  const [config, setConfig] = useState('');
  const [conversationType, setConversationType] = useState({});
  const [loading, setLoading] = useState(true);

  const createNotification = useCreateNotification();

  async function getConfig() {
    try {
      const { data } = await axios.get(`/api/conversation-type/${id}`);
      setConversationType(data);
      setConfig(data.config);
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getConfig();
  }, []);

  async function saveConfig() {
    try {
      JSON.parse(config); // Validate JSON
      await axios.post(`/api/admin/conversation-type/${id}/config`, config);
      createNotification({
        message: 'Configuration saved successfully.',
        type: 'success',
      });
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    }
  }

  if (loading) return <Loader />;

  return (
    <div className="container mt-5">
      <h1 className="mb-4">
        Configuring{' '}
        {truncateText(conversationType.rolePrompt, promptTruncatedLength)}
      </h1>
      <div className="form-group">
        <AceEditor
          mode="json"
          theme="github"
          name="configEditor"
          value={config}
          onChange={(newValue) => setConfig(newValue)}
          editorProps={{ $blockScrolling: true }}
          setOptions={{ useWorker: false }}
          width="100%"
          height="50rem"
        />
      </div>

      <button className="btn btn-primary" onClick={saveConfig}>
        Save
      </button>
    </div>
  );
}
