import axios from 'axios';
import React, { useEffect, useState } from 'react';

import PricingResults from '../../../components/pricing/PricingResults.jsx';
import PromptSelector from '../../../components/pricing/PromptSelector.jsx';
import CustomInput from '../../../components/utils/CustomInput.jsx';
import { useCreateNotification } from '../../../utils/toast.js';

export default function ConversationCalculator() {
  const [formData, setFormData] = useState({
    systemPromptLength: 1774,
    userPromptLength: 4134,
    messagesLength: 288,
    messagesPerMinute: 5,
  });
  const [pricingData, setPricingData] = useState(null);
  const createNotification = useCreateNotification();

  async function fetchPricingData() {
    try {
      const params = new URLSearchParams(formData);
      const { data } = await axios.get(
        `/api/admin/pricing/conversation?${params.toString()}`,
      );
      setPricingData(data);
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    }
  }

  useEffect(() => {
    fetchPricingData();
  }, [formData]);

  function handleInputChange(e) {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  function calculateCosts(multiplier) {
    if (!pricingData) return null;

    const scaledCosts = {
      totalGptCost: pricingData.totalGptCost * multiplier,
      totalAzureCost: pricingData.totalAzureCost * multiplier,
      voiceMinutes: pricingData.voiceMinutes * multiplier,
      speechRecognition: pricingData.speechRecognition * multiplier,
      twilioRecordings: pricingData.twilioRecordings * multiplier,
    };

    const usageCosts =
      scaledCosts.totalGptCost +
      scaledCosts.totalAzureCost +
      scaledCosts.voiceMinutes +
      scaledCosts.speechRecognition;

    const monthlyCosts = scaledCosts.twilioRecordings;
    const totalCosts = usageCosts + monthlyCosts;

    return {
      ...scaledCosts,
      usageCosts,
      monthlyCosts,
      totalCosts,
    };
  }

  const timePeriods = [
    { label: '1 Minute', value: 1 },
    { label: '1 Hour', value: 60 },
    { label: '1 Day', value: 60 * 24 },
    { label: '160 Hours', value: 60 * 160 },
  ];

  return (
    <div className="container mt-5">
      <PromptSelector
        onSystemPromptChange={(v) =>
          setFormData((state) => ({ ...state, systemPromptLength: v }))
        }
        onUserPromptChange={(v) =>
          setFormData((state) => ({ ...state, userPromptLength: v }))
        }
      />
      <div className="row mt-5">
        <div className="col-md-5">
          <h2 className="mb-4">Input Parameters</h2>
          <CustomInput
            label="System Prompt Length"
            id="systemPromptLength"
            type="number"
            value={formData.systemPromptLength}
            onChange={handleInputChange}
            required
          />
          <CustomInput
            label="User Prompt Length"
            id="userPromptLength"
            type="number"
            value={formData.userPromptLength}
            onChange={handleInputChange}
            required
          />
          <CustomInput
            label="Messages Length"
            id="messagesLength"
            type="number"
            value={formData.messagesLength}
            onChange={handleInputChange}
            required
          />
          <CustomInput
            label="Total messages in a minute"
            id="messagesPerMinute"
            type="number"
            value={formData.messagesPerMinute}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="col-md-7">
          <h2 className="mb-4">Pricing Results</h2>
          {timePeriods.map((period) => (
            <div key={period.value} className="mt-2">
              <PricingResults
                costs={calculateCosts(period.value)}
                title={period.label}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
