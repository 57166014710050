import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Pagination from '../../../components/Pagination.jsx';
import ContactsTable from '../../../components/contacts/Table.jsx';
import ConversationTypePipelineSelector from '../../../components/conversation/ConversationTypePipelineSelector.jsx';
import { useCreateNotification } from '../../../utils/toast.js';

function ContactsFilter() {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pipeline, setPipeline] = useState({
    pipelineId: '',
    stageId: '',
  });
  const [submitting, setSubmitting] = useState(false);
  const [contacts, setContacts] = useState({ opportunities: [], meta: {} });
  // Implemented as a stack to allow for multiple back navigations
  const [paginationHistory, setPaginationHistory] = useState([{}]);
  const createNotification = useCreateNotification();

  async function getContacts(startAfter = '', startAfterId = '') {
    if (!pipeline.stageId) return;
    if (submitting) return;
    setSubmitting(true);

    try {
      const params = new URLSearchParams({
        pipelineId: pipeline.pipelineId,
        stageId: pipeline.stageId,
        startAfter,
        startAfterId,
        limit,
      });
      const { data } = await axios.get(
        `/api/admin/conversation-type/${id}/pipeline/contacts` +
          `?${params.toString()}`,
      );
      setContacts(data);
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    setContacts({ opportunities: [], meta: {} });
    setPaginationHistory([{}]); // First position is always empty
    if (pipeline.stageId) {
      getContacts();
      setCurrentPage(1);
    } else {
      // Keep page to 0 if stageId is not selected
      setCurrentPage(0);
    }
  }, [pipeline.stageId, limit]);

  function handlePageChange(newPage) {
    if (newPage > currentPage) {
      const { startAfter, startAfterId } = contacts.meta;
      setPaginationHistory((prev) => [...prev, { startAfter, startAfterId }]);
      getContacts(startAfter, startAfterId);
    } else {
      const prevPageInfo = paginationHistory[paginationHistory.length - 2];
      // Pop the last page info from the stack
      setPaginationHistory((prev) => prev.slice(0, -1));
      getContacts(prevPageInfo.startAfter, prevPageInfo.startAfterId);
    }
    setCurrentPage(newPage);
  }

  return (
    <div>
      <ConversationTypePipelineSelector
        id={id}
        selection={pipeline}
        onSelectionChange={(v) => setPipeline(v)}
      />
      <div className="d-flex justify-content-end mb-3">
        <label htmlFor="limitSelect" className="me-2">
          Items per page:
        </label>
        <select
          id="limitSelect"
          className="form-select form-select-sm w-auto"
          value={limit}
          onChange={(e) => setLimit(Number(e.target.value))}
        >
          <option value="10">10</option>
          <option value="20">20</option>
        </select>
      </div>
      {/* TODO: Improve error handling and pagination logic
        to better manage query failures */}
      <ContactsTable contacts={contacts} conversationTypeId={id} />
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(contacts.meta.total / limit) || 0}
        onPageChange={handlePageChange}
        isLoading={!pipeline.stageId || submitting}
      />
    </div>
  );
}

export default ContactsFilter;
