import { Link } from 'react-router-dom';

import CustomCard from '../utils/CustomCard.jsx';

export default function MessagingAccountConnector() {
  const igPermissionUrl =
    'https://www.instagram.com/oauth/authorize' +
    '?enable_fb_login=0' +
    '&force_authentication=1' +
    `&client_id=${process.env.REACT_APP_META_CLIENT_ID}` +
    '&redirect_uri=' +
    encodeURIComponent(
      `${process.env.REACT_APP_BASE_URL}/user/meta-api-redirect`,
    ) +
    '&response_type=code' +
    '&scope=' +
    encodeURIComponent(
      'instagram_business_basic,instagram_business_manage_messages',
    );

  return (
    <CustomCard width={6}>
      <h3 className="mb-4">Connect a New Account</h3>
      <p className="text-muted mb-4">
        Connecting an account will allow us to receive and respond to your DMs
        on your behalf.
      </p>
      <div className="d-grid gap-2">
        <Link
          to={igPermissionUrl}
          className="btn d-flex align-items-center justify-content-center gap-2"
          style={{
            backgroundColor: '#0095f6',
            color: 'white',
            border: 'none',
            padding: '0.5rem 1rem',
            fontWeight: 600,
            borderRadius: '8px',
          }}
        >
          <i className="bi bi-instagram" style={{ fontSize: '1.2rem' }}></i>
          Connect your Instagram Account
        </Link>
      </div>
    </CustomCard>
  );
}
