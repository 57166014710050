import axios from 'axios';

export async function makeCall(
  phoneNumber,
  conversationTypeId,
  contactId,
  useRealtimeApi = undefined,
) {
  const { data } = await axios.post(`/api/admin/call/${phoneNumber}`, {
    conversationTypeId,
    contactId,
    useRealtimeApi,
  });
  return data;
}
